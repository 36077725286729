/*
Achtung! Die range-Werte können bei den einzelnen Kategorien nicht
unterschiedlich sein. Das Feld wird intern nur 1x gebaut.
*/

export default {
  categoryList: [
    {
      id: 22332,
      text: "haus",
      name: "efh",
      objectType: "LIVING",
      fieldList: [
        { var: "price.price", range: "minmax" },
        { var: "area.livingSpace", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.numberRooms", range: "min" },
        { var: "property[baujahr]", range: "minmax" }
      ]
    },
    {
      id: 30225,
      text: "wohnung",
      name: "eg",
      objectType: "LIVING",
      fieldList: [
        { var: "price.price", range: "minmax" },
        { var: "area.livingSpace", range: "min" },
        { var: "area.plotArea", range: "min" },
        { var: "area.numberRooms", range: "min" },
        { var: "property[baujahr]", range: "minmax" }
      ]
    },
    {
      id: 19795,
      text: "grundstueck",
      name: "grund",
      marketingType: "BUY",
      fieldList: [
        { var: "price.price", range: "minmax" },
        { var: "area.plotArea", range: "min" }
      ]
    },
    {
      id: 42723,
      text: "buero_praxis",
      name: "buero",
      objectType: "COMMERCIAL",
      fieldList: [
        { var: "price.price", range: "minmax" },
        "area.plotArea", "property[baujahr]"
      ]
    },
    {
      id: 82397,
      text: "einzelhandel",
      name: "einzelhandel",
      objectType: "COMMERCIAL",
      fieldList: [
        { var: "price.price", range: "minmax" },
        "area.plotArea", "property[baujahr]"
      ]
    },
    {
      id: 11080,
      text: "hallen_lager",
      name: "lager",
      objectType: "COMMERCIAL",
      fieldList: [
        { var: "price.price", range: "minmax" },
        "area.plotArea", "property[baujahr]"
      ]
    },
    {
      id: 30911,
      text: "gastgewerbe",
      name: "gastgewerbe",
      objectType: "COMMERCIAL",
      fieldList: [
        { var: "price.price", range: "minmax" },
        "area.plotArea", "property[baujahr]"
      ]
    },
  ]
};
