<template>
<div>
  <v-text-field :value="cvalue" :outlined="true" :suffix="suffix" @focus="change" @blur="blur" :label="label" @input="input" :filled="filled"></v-text-field>
</div>
</template>

<script>
export default {
  name: "EditTextField",

  props: {
    format: [String, Number],
    value: Number,
    suffix: String,
    label: String,
    filled: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mode: "show"
    }
  },

  computed: {
    cvalue() {
      if (this.mode == "show") {
        if (!this.value) {
          return null;
        }

        return this.$n(this.value, this.format);
      }

      return this.value;
    },

    type() {
      if (this.mode == "edit") {
        return "number";
      }

      return "text";
    }
  },

  methods: {
    input(val) {
      val = val.replace(/\./g, "");
      val = val.replace(/,/g, ".");
      let n = parseFloat(val);
      if (Number.isNaN(n)) {
        n = "";
      }

      this.$emit("input", n);
    },

    change() {
      if (this.mode == "show") {
        this.mode = "edit";
      }
    },

    blur() {
      if (this.mode == "edit") {
        this.mode = "show";
      }
    }
  }
};
</script>
