<template>
<v-autocomplete
  v-model="select"
  :items="items"
  :loading="loading"
  :search-input.sync="search"
  hide-no-data
  no-filter
  return-object

  :menu-props="{ offsetY: true, fixed: true }"
  :label="$t('Standort')"
  :placeholder="placeholder"

  no-data-text="Keine passenden Orte gefunden"
  attach
  @change="change"
  filled
  flat

  clearable
></v-autocomplete>
</template>

<script>
import { GeocoderFactory } from "./Geocoder";

let timeout = null;

export default {
  name: "GeocoderAutocomplete",

  props: [
    "value",
    "placeholder",
    "hide-details"
  ],

  data() {
    return {
      loading: false,
      search: null,
      select: null,
      items: []
    };
  },

  created() {
    GeocoderFactory().then(geocoder => {
      this.geocoder = geocoder

      if (this.value.value) {
        this.items.push(this.value);
        this.select = this.value;
      }
    });
  },

  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val) // eslint-disable-line
    }
  },

  methods: {
    querySelections(value) {
      this.loading = true;

      if (timeout != null) {
        clearTimeout(timeout);
      }

      this.geocoder.cancelAutocomplete();

      timeout = setTimeout(() => {
        this.geocoder.autocomplete(value).then(items => {
          this.items = [];
          items.forEach(g => {
            this.items.push(g);
          });
        }).finally(() => {
          this.loading = false;
        });
      }, 150);
    },

    change(o) {
      this.$emit("change", o);
    }
  }
};
</script>
