<template>
<v-app>
  <contact-form
    :name="name"
    :authentication-token="authenticationToken"
    :required-fields="requiredFields"
    buttonWrapperClass="text-center"
    buttonText="Anfrage senden">
      <contact-logged-in></contact-logged-in>
      <contact-fields :exclude="exclude"></contact-fields>
  </contact-form>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFields";
import ContactLoggedIn from "../immoapp/contact/ContactLoggedIn";

export default {
  name: "ContactFormApp",

  components: {
    ContactForm,
    ContactFields,
    ContactLoggedIn
  },

  data() {
    return {
      name: "contact"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    authenticationToken() {
      if (this.$store && this.$store.state) {
        return this.$store.state.auth.authenticationToken;
      }

      return null;
    },

    loggedIn() {
      if (this.$store && this.$store.state) {
        return this.$store.state.user.loggedIn;
      }

      return false;
    },

    exclude() {
      let ignoreFields = [];
      if (this.loggedIn) {
        ignoreFields = ["email"];
      }

      let el = [];
      if (this.$root.exclude) {
        el = this.root.exclude;
      }

      return ignoreFields.concat(el);
    }
  }
};
</script>
