export default {
  async asyncImport(name) {
    if (name === "swiper") {
      return import(/* webpackChunkName: "swiper" */ "./swiper.js");
    }

    if (name == "axios") {
      return import(/* webpackChunkName: "axios" */ "./axios.js");
    }

    if (name == "vuex") {
      return import(/* webpackChunkName: "vuex" */ "./vuex.js");
    }

    if (name == "vue-filters") {
      return import(/* webpackChunkName: "vue-filters" */ "./vue-filters");
    }

    if (name == "leaflet") {
      return import(/* webpackChunkName: "leaflet" */ "./leaflet");
    }

    if (name == "lightgallery") {
      return import(/* webpackChunkName: "lightgallery" */ "./lightgallery");
    }

    if (name == "vue-i18n") {
      return import(/* webpackChunkName: "vue-i18n" */ "./vue-i18n");
    }

    if (name == "js-cookie") {
      return import(/* webpackChunkName: "js-cookie" */ "./js-cookie");
    }

    if (name == "iframe-resizer") {
      return import(/* webpackChunkName: "iframe-resizer" */ "./iframe-resizer");
    }

    return null;
  },

  async load(name) {
    const res = await this.asyncImport(name);
    return res.default;
  }
};
