<template>
<app-wrapper :standalone="true">
  <contact-form :requiredFields='requiredFields'
    :authentication-token="authenticationToken"
    :prop-immo-expose="[exposeId, customObjectId]"
    buttonText="Anfrage senden"
    buttonWrapperClass="text-center"

    name="exposeContact"
    buttonClass="btn btn-primary btn-sm btn-snomw"
  >
    <contact-logged-in></contact-logged-in>
    <contact-fields v-if="loggedIn" :exclude="['company', 'person', 'phone_email']"></contact-fields>
    <contact-fields v-else :exclude="['company']"></contact-fields>
  </contact-form>
</app-wrapper>
</template>

<script>
import AppWrapper from "../AppWrapper";
import ContactLoggedIn from "./ContactLoggedIn";
import ContactForm from "../../contact/ContactForm";
import ContactFields from "../../contact/ContactFields";

export default {
  name: "ExposeContactStandaloneApp",

  components: {
    AppWrapper,
    ContactForm,
    ContactFields,
    ContactLoggedIn
  },

  props: [
    "exposeId",
    "customObjectId"
  ],

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields("exposeContact");
    },

    loggedIn() {
      return this.$store.state.user.loggedIn;
    },

    username() {
      return this.$store.state.user.email;
    },

    appLoaded() {
      return this.$store.state.appLoaded;
    },

    authenticationToken() {
      return this.$store.state.auth.authenticationToken;
    }
  }
};
</script>
