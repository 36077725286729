<template>
<form>
    <div class="form-horizontal wizard__places-container sw-form">
      <div class="row">
        <div class="col-24 col-lg-16">
          <geocoder-autocomplete-outlined :placeholder="autocomplete" v-model="autocomplete" @change="geoaddress"></geocoder-autocomplete-outlined>
        </div>
        <div class="col-24 col-lg-8">
          <v-select
            :items="radiusList"
            :value="radius"
            :label="$t('Umkreis')"
            @change="changeRadius($event)"
            attach
            outlined
            filled
          ></v-select>
        </div>
      </div>

        <div class="mb-1">
          <standard-map :value="mapCenter" @click="mapClick" :zoom="mapZoom" @initialized="onMapLoad"></standard-map>
        </div>

        <div v-show="location">
            <div class="row">
                <div class="col-sm-6 col-24">
                    <v-text-field :value="zip" @input="change('zip', $event)" :label="$t('PLZ')" filled outlined></v-text-field>
                </div>

                <div class="col-sm-9 col-24">
                    <v-text-field :value="city" @input="change('city', $event)" :label="$t('Stadt')" filled outlined></v-text-field>
                </div>

                <div class="col-sm-9 col-24">
                    <v-text-field :value="district" @input="change('district', $event)" :label="$t('Ortsteil')" filled outlined></v-text-field>
                </div>
            </div>

            <div class="row" v-if="street">
                <div class="col-sm-18 col-16">
                    <v-text-field :value="street" @input="change('street', $event)" :label="$t('Straße')" filled outlined></v-text-field>
                </div>

                <div class="col-sm-6 col-8">
                    <v-text-field :value="number" @input="change('number', $event)" :label="$t('Haus-Nr.')" filled outlined></v-text-field>
                </div>
            </div>
        </div>
    </div>

</form>
</template>

<script>
import { GeocoderFactory } from "../utils/Geocoder";
import GeocoderAutocompleteOutlined from "../utils/GeocoderAutocompleteOutlined";
import StandardMap from "../../StandardMap";

let L;

export default {
  name: "InquiryLocation",

  components: {
    GeocoderAutocompleteOutlined,
    StandardMap
  },

  props: [
    "location"
  ],

  data() {
    return {
      autocomplete: null,
      map: null,
      mapCenter: null,
      mapMarker: null,
      mapCircle: null,
      mapLoaded: false,
      radiusList: [
        {
          "text": "1 km", // default Wert hier drin lassen
          "value": 1
        },
        {
          "text": "5 km",
          "value": 5
        },
        {
          "text": "10 km",
          "value": 10
        },
        {
          "text": "15 km",
          "value": 15
        },
        {
          "text": "25 km",
          "value": 25
        }
      ]
    };
  },

  computed: {
    mapZoom() {
      return 12;
    },

    radius() {
      if (this.location && this.location.radius) {
        return this.location.radius;
      }

      return 1;
    },

    zip() {
      if (this.location && this.location.zip) {
        return this.location.zip;
      }

      return null;
    },

    city() {
      if (this.location && this.location.city) {
        return this.location.city;
      }

      return null;
    },

    district() {
      if (this.location && this.location.district) {
        return this.location.district;
      }

      return null;
    },

    street() {
      if (this.location && this.location.street) {
        return this.location.street;
      }

      return null;
    },

    number() {
      if (this.location && this.location.number) {
        return this.location.number;
      }

      return null;
    }
  },

  methods: {
    geoaddress(geo) {
      if ("text" in geo) {
        this.autocomplete = geo.text;
      } else {
        this.autocomplete = geo.zip + " " + geo.city;
      }

      this.mapCenter = [geo.lat, geo.lng];

      if (!this.mapMarker) {
        this.mapMarker = L.marker([geo.lat, geo.lng]).addTo(this.map);
      } else {
        this.mapMarker.setLatLng([geo.lat, geo.lng]);
      }

      this.updateCircle();

      this.$emit("update", {
        zip: geo.zip,
        city: geo.city,
        district: geo.district,
        street: geo.street,
        number: geo.number,
        lat: geo.lat,
        lng: geo.lng,
        radius: this.radius
      });
    },

    updateCircle() {
      if (!this.map || !this.mapCenter) {
        return;
      }

      if (this.radius == 0) {
        if (this.mapCircle) {
          this.mapCircle.remove();
          this.mapCircle = null;
          this.map.scrollWheelZoom.enable();
        }
        return;
      }

      if (!this.mapCircle) {
        this.mapCircle = L.circle(this.mapCenter, {radius: this.radius * 1000}).addTo(this.map);
      } else {
        this.mapCircle.setLatLng(this.mapCenter);
        this.mapCircle.setRadius(this.radius * 1000);
      }

      setTimeout(() => {
        const bounds = this.mapCircle.getBounds();
        this.map.fitBounds(bounds);
        this.map.scrollWheelZoom.disable();
      }, 50);
    },

    mapClick(event) {
      this.geocoder.reverse(event.latlng.lat, event.latlng.lng).then(geoaddress => {
        if (geoaddress) {
          this.geoaddress(geoaddress);
        }
      });
    },

    onMapLoad(o) {
      this.mapLoaded = true;

      this.map = o.map;
      L = o.L;

      this.mapCenter = o.defaultCenter;

      GeocoderFactory().then(geocoder => {
        this.geocoder = geocoder;

        if (this.location && this.location.lat && this.location.lng) {
          this.geocoder.reverse(this.location.lat, this.location.lng).then(() => {
            // ignore geoaddress
            this.geoaddress(this.location);
          });
        }
      });
    },

    change(k, $event) {
      const e = {};
      e[k] = $event;
      this.$emit("update", e);
    },

    changeRadius(radius) {
      this.$emit("update", {"radius": radius});
      this.updateCircle();
    }
  }
};
</script>
